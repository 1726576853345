








import {Component, Vue} from "vue-property-decorator";
import MenuStore from "@/Store/Modules/MenuStore";
import {getModule} from "vuex-module-decorators";

@Component
export default class Cronjobs extends Vue {
    menuStore: MenuStore = getModule(MenuStore);
}
